<template>
  <svg class="icon icon--basket" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable vue/max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.083 2.5H7.917l-.834.833H4.167V5h11.666V3.333h-2.916l-.834-.833zm1.25 5v8.333H6.667V7.5h6.666zM5 5.833h10v10c0 .917-.75 1.667-1.667 1.667H6.667C5.75 17.5 5 16.75 5 15.833v-10z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VBasket'
}
</script>
