<template>
  <div
    :class="['v-input-file', `v-input-file--view-rectangle`, isError && 'v-input-file--error']"
    @drop.prevent.stop="handleFileChange($event.dataTransfer.files)"
    @dragover.prevent.stop
    @drag.prevent.stop
    @dragstart.prevent.stop
    @dragend.prevent.stop
    @dragenter.prevent.stop
    @dragleave.prevent.stop
  >
    <v-input-file ref="fileInput" view="button" :accept="accept" multiple @change="handleFileChange"
      ><slot name="input-text"> Выберите фото </slot></v-input-file
    >
    <span>
      <slot name="dropzone-text">
        или перетащите в область
      </slot>
    </span>
  </div>
</template>

<script>
import VInputFile from '@/components/common/VInputFile.vue'

export default {
  name: 'VInputFileDropzone',
  components: { VInputFile },
  props: {
    isError: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '*'
    }
  },
  methods: {
    handleFileChange(files) {
      this.$emit('change', files)
    }
  }
}
</script>
