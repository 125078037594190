<template>
  <label :class="['v-input-file', `v-input-file--view-${view}`, isError && 'v-input-file--error']">
    <v-icon-plus v-if="view !== 'button'" class="v-input-file__add-icon" size="medium" />
    <span v-if="$slots.default" class="v-input-file__title">
      <slot></slot>
    </span>

    <input
      ref="fileInput"
      class="v-input-file__hidden"
      v-bind="$attrs"
      type="file"
      :accept="accept"
      @change="handleFileChange"
    />
  </label>
</template>

<script>
import VIconPlus from '@/components/icons/VPlus.vue'

export default {
  name: 'VInputFile',
  components: { VIconPlus },
  inheritAttrs: false,
  props: {
    view: {
      type: String,
      default: 'rectangle',
      validate: view => ['round', 'rectangle', 'button'].includes(view)
    },
    isError: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '*'
    }
  },
  methods: {
    handleFileChange(event) {
      this.$emit('change', event.target.files)
      this.$refs.fileInput.value = ''
    }
  }
}
</script>
