<template>
  <div>
    <draggable v-model="localPhotos" class="image-uploader-with-preview" draggable=".item" @start.stop @end.stop>
      <div
        v-for="(photo, index) in localPhotos"
        :key="index"
        class="image-uploader-with-preview__cell"
        :class="{ item: photo.id && !disableDragOrdering }"
      >
        <div class="image-uploader-with-preview__image-wrapper">
          <slot name="preview" :photo="photo">
            <v-image-preview
              :image="photo.image"
              :progress="photo.progress"
              :hide-delete-button="hideInput"
              class="image-uploader-with-preview__image"
              @remove="removePhoto(photo)"
            />
          </slot>
        </div>
      </div>
    </draggable>

    <template v-if="lessThanMax && !hideInput">
      <v-input-file-dropzone
        v-if="withDropZone"
        class="image-uploader-with-preview__add image-uploader-with-preview__add--full-width"
        accept="image/jpeg,image/png"
        @change="addPhotos"
      >
        <template #input-text> <slot name="input-text"></slot></template>
        <template #dropzone-text> <slot name="dropzone-text"></slot></template>
      </v-input-file-dropzone>

      <v-input-file
        v-else
        slot="footer"
        accept="image/jpeg,image/png"
        class="image-uploader-with-preview__add"
        :multiple="multiple"
        @change="addPhotos"
      >
        <slot name="input-text"> Загрузить фото </slot>
      </v-input-file>
    </template>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import VInputFile from '@/components/common/VInputFile.vue'
import VImagePreview from '@/components/common/VImagePreview.vue'
import { generateUniqName } from '@/utils/common'
import { uploadingService } from '@/services/http'
import { PENDING_STATUS, FINISHED_STATUS } from '@/constants/photoUploadStatuses'
import VInputFileDropzone from '@/components/common/VInputFileDropzone.vue'

export default {
  name: 'VImageUploaderWithPreview',
  components: { VInputFileDropzone, draggable, VInputFile, VImagePreview },
  props: {
    photos: { type: Array, required: true },
    maxPhotos: {
      type: Number,
      default: 0
    },
    hideInput: {
      type: Boolean,
      default: false
    },
    disableDragOrdering: {
      type: Boolean,
      default: false
    },
    withDropZone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      temporaryPhotos: [],
      urls: [],
      uploadingCount: 0
    }
  },
  computed: {
    localPhotos: {
      get() {
        return [...this.photos, ...this.temporaryPhotos]
      },
      set(val) {
        this.$emit('change', val)
      }
    },
    lessThanMax() {
      return !this.maxPhotos || this.localPhotos.length < this.maxPhotos
    },
    multiple() {
      return this.maxPhotos !== 1
    }
  },
  watch: {
    uploadingCount(val) {
      if (val === 0) {
        this.temporaryPhotos.forEach(photo => {
          this.$emit('add', photo)
        })
        this.temporaryPhotos = []
        this.$emit('set-uploading-status', FINISHED_STATUS)
      } else {
        this.$emit('set-uploading-status', PENDING_STATUS)
      }
    }
  },
  beforeDestroy() {
    this.urls.forEach(url => {
      URL.revokeObjectURL(url)
    })
    this.temporaryPhotos.forEach(photo => {
      URL.revokeObjectURL(photo.image)
    })
  },
  methods: {
    addPhotos(photos) {
      photos.forEach(photo => {
        if (this.lessThanMax) {
          const newName = generateUniqName()
          const extension = photo.name.split('.').pop()
          const newFullName = `${newName}.${extension}`
          const newImage = new File([photo], newFullName, {
            type: photo.type,
            lastModified: photo.lastModified
          })
          const url = URL.createObjectURL(newImage)
          const tmpPhoto = { image: url, progress: 0 }
          this.temporaryPhotos.push(tmpPhoto)
          this.uploadingCount += 1

          uploadingService
            .uploadImage(newImage, this.getUpdateLoadingProgressCb(tmpPhoto))
            .then(({ id }) => {
              this.urls.push(tmpPhoto.image)
              this.temporaryPhotos = this.temporaryPhotos.map(p =>
                p === tmpPhoto ? { id, image: url, fileName: newFullName } : p
              )
            })
            .catch(() => {
              this.temporaryPhotos = this.temporaryPhotos.filter(p => p !== tmpPhoto)
            })
            .finally(() => {
              this.uploadingCount -= 1
            })
        }
      })
    },
    getUpdateLoadingProgressCb(photo) {
      const tmpPhoto = this.temporaryPhotos.find(p => p === photo)

      return progressEvent => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader('x-decompressed-content-length')

        if (totalLength) {
          tmpPhoto.progress = progressEvent.loaded / totalLength
        }
      }
    },
    removePhoto(photo) {
      this.$emit('remove', photo)
    }
  }
}
</script>
