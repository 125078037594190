<template>
  <match-media v-slot="{ mobile }">
    <div class="v-image-preview">
      <span class="v-image-preview__photo" :style="{ backgroundImage: `url(${image})` }">
        <template v-if="!loading">
          <span v-if="!mobile" class="v-image-preview__photo-button" @click="removeImage">
            <v-icon-basket />
          </span>
          <span v-else class="v-image-preview__photo-button-mobile" @click="removeImage">
            <v-icon-cross />
          </span>
        </template>

        <span v-else class="v-image-preview__loader-wrapper">
          <span class="v-image-preview__loader-track">
            <span class="v-image-preview__loader" :style="{ width: `${progress * 100}%` }"></span>
          </span>
        </span>
      </span>
    </div>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VIconBasket from '@/components/icons/VBasket.vue'
import VIconCross from '@/components/icons/VCross.vue'

export default {
  name: 'VImagePreview',
  components: { MatchMedia, VIconBasket, VIconCross },
  inheritAttrs: false,
  props: {
    image: { type: String, required: true },
    progress: { type: Number, default: undefined }
  },
  computed: {
    loading() {
      return this.progress !== undefined
    }
  },
  methods: {
    removeImage() {
      this.$emit('remove')
    }
  }
}
</script>
